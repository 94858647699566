import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import CarsListStyles from "../../cars/CarsList/styles";
import { SpinnerWrapper, Spinner } from "../../../styled/Spinner";
import {ReactComponent as CanceledIcon} from 'assets/icons/canceled_icon.svg';
import {
  TabsContentInner,
  TabsContentWrapper,
  TabsContentContainer,
  TabsContentTitle,
  TabsContentDriverWrap,
  TabsContentDriverInfoWrap,
  TabsContentDriverName,
  TabsContentDriverInfo,
  TabsContentTripInfoWrap,
  TabsContentTripInfoList,
  TabsContentTripInfoItem,
  TabsContentTripInfoIconWrap,
  TabsContentTripInfo,
  TabsContentTripInfoTitle,
  BulletsItem,
  TaskStatusLabel,
  TaskIconWrapper,
  CanceledIconWrapper,
} from "./styles";
import ContentInfo from "components/__common/ContentInfo";
import { ReactComponent as DriverIcon } from "assets/icons/drivers.svg";
import { ReactComponent as TwoBulletsIcon } from "assets/icons/twiceBullets.svg";
import { ReactComponent as ThreeBulletsIcon } from "assets/icons/threeBullets.svg";
import StartIcon from "assets/react-icons/startIcon";
import FinishIcon from "assets/react-icons/finishIcon";
import TransferIcon from "assets/react-icons/transferIcon";
import { withNamespaces } from 'react-i18next';
import theme from "config/theme";

const TabsContent = ({
  primaryIconInfo,
  secondaryIconInfo,
  primaryTextInfo,
  secondaryTextInfo,
  route,
  routeLoading,
  isActiveTabsContent,
  t
}) => {
  const routeDetails = useSelector(({ routes }) => routes.routeDetails);
  const carsColors = useSelector(({ cars }) => cars.carsColors);

  return (
    <TabsContentWrapper>
      <TabsContentInner>
        <TabsContentContainer isActiveTabsContent={isActiveTabsContent}>
          {routeLoading ? (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          ) : (routeDetails && routeDetails.tasks && routeDetails.tasks.length) ? (
            <>
              {routeDetails.drivers && routeDetails.drivers.length
                ? routeDetails.drivers.map((driver) => {
                    return (
                      <Fragment key={driver.id}>
                        <TabsContentTitle>{t('cars.drivers')}</TabsContentTitle>
                        <TabsContentDriverWrap>
                          <DriverIcon />
                          <TabsContentDriverInfoWrap>
                            <TabsContentDriverName>
                              {driver.name}
                            </TabsContentDriverName>
                            <TabsContentDriverInfo>
                              {driver.phone && driver.email ? (
                                <>
                                  <ContentInfo
                                    href={`tel: ${driver.phone}`}
                                    icon={primaryIconInfo}
                                    text={primaryTextInfo}
                                  />
                                  <ContentInfo
                                    href={`mailto: ${driver.email}`}
                                    icon={secondaryIconInfo}
                                    text={secondaryTextInfo}
                                  />
                                </>
                              ) : null}
                            </TabsContentDriverInfo>
                          </TabsContentDriverInfoWrap>
                        </TabsContentDriverWrap>
                      </Fragment>
                    );
                  })
                : null}
              <TabsContentTripInfoWrap>
                <TabsContentTitle>
                  {`${t('cars.trip')} `}
                  {routeDetails.type === "dowoz"
                    ? t('cars.tripTypes.shippers')
                    : routeDetails.type === "cel"
                    ? t('cars.tripTypes.target')
                    : t('cars.tripTypes.delivers')}
                </TabsContentTitle>
                <TabsContentTripInfoList>
                  <>
                    {routeDetails.tasks.map((task, i) => {
                      return (
                        <TabsContentTripInfoItem key={task.id}>
                          <TabsContentTripInfoIconWrap>
                            {i === 0 ? (
                              <StartIcon color={carsColors[route.car.id]} />
                            ) : i === routeDetails.tasks.length - 1 ? (
                              <FinishIcon color={carsColors[route.car.id]} />
                            ) : (
                              <TaskIconWrapper>
                                <TransferIcon color={task.cancelled ? theme.colors.blueyGrey : carsColors[route.car.id]} index={i} />
                                {task.cancelled &&
                                  <CanceledIconWrapper>
                                    <CanceledIcon />
                                  </CanceledIconWrapper>
                                }
                              </TaskIconWrapper>
                            )}
                          </TabsContentTripInfoIconWrap>
                          <TabsContentTripInfo>
                            {task.cancelled
                              ? <TaskStatusLabel>{t('route.taskCancelled')}</TaskStatusLabel>
                              : (
                                <TabsContentTripInfoTitle>
                                  {i === 0
                                    ? `Start ${task.times && task.times.time && task.time_visible ? task.times.time : ''}`
                                    : i === routeDetails.tasks.length - 1
                                      ? `Stop ${task.times && task.times.time && task.time_visible ? task.times.time : ''}`
                                      : task.times && task.times.time && task.time_visible
                                        ? task.times.time
                                        : null
                                  }
                                </TabsContentTripInfoTitle>
                              )
                            }
                            <TabsContentDriverName isCancelled={task.cancelled}>
                              {task.contact && task.contact.address}
                            </TabsContentDriverName>
                            {i !== 0 &&
                              i !== routeDetails.tasks.length - 1 &&
                              task.contact && (
                                <TabsContentTripInfoTitle isBottom>
                                  {task.contact.name} {task.contact.phone}
                                </TabsContentTripInfoTitle>
                              )}
                          </TabsContentTripInfo>
                          {i !== 0 &&
                            (i === 1 ? (
                              <BulletsItem>
                                <TwoBulletsIcon />
                              </BulletsItem>
                            ) : (
                              <BulletsItem>
                                <ThreeBulletsIcon />
                              </BulletsItem>
                            ))}
                        </TabsContentTripInfoItem>
                      );
                    })}
                    <TabsContentTripInfoItem />
                  </>
                </TabsContentTripInfoList>
              </TabsContentTripInfoWrap>
            </>
        ) : (
          routeDetails.tasks && !routeDetails.tasks.length && (
            <CarsListStyles.CarsNotFound bgColor>
              {t('cars.tripNotPlanned')}
            </CarsListStyles.CarsNotFound>
          )
        )}
        </TabsContentContainer>
      </TabsContentInner>
    </TabsContentWrapper>
  );
};

export default withNamespaces()(TabsContent);
