import React, {useState} from 'react';
import LoginFormStyles from './styles.js';
import {ReactComponent as ArrowRight} from 'assets/icons/arrow.svg';
import Logo from 'assets/img/logo.svg';
import {ButtonLoader} from "styled/ButtonLoader";
import {InputBar, InputLabel, Input, Label} from 'styled/Form';
import {PrimaryButtonText, PrimaryButtonIcon} from 'styled/Buttons';
import {withNamespaces} from 'react-i18next';
import SelectLanguage from "../../__common/SelectLanguage";
import SelectDatabase from "../../__common/SelectDatabase";

const LoginForm = ({isLogging, onSubmit, error, resetError, basicConfig, t}) => {
  const [formInputs, setFormInputs] = useState({
    login: '',
    password: '',
    database: 1,
  });

  const handleFormChange = () => {
    if (error) resetError();
  };

  const handleInputChange = e => {
    setFormInputs({
      ...formInputs,
      [e.target.name]: e.target.value
    });
  };

  const handleSelectChange = value => {
    setFormInputs({
      ...formInputs,
      database: value
    });
  };

  const handleSubmit = e => {
    if (error) resetError();
    onSubmit(e, formInputs)
  };

  return (
    <LoginFormStyles.ContentWrapper>
      <LoginFormStyles.LoginHeader>
        <img src={Logo} alt={t('login.logoAlt')}/>
        <LoginFormStyles.LoginHeaderInfo>{basicConfig && !basicConfig.company_domain ? t('login.header') : t('login.companyHeader')}</LoginFormStyles.LoginHeaderInfo>
      </LoginFormStyles.LoginHeader>
      <LoginFormStyles.SelectWrapper>
        <SelectLanguage/>
        {basicConfig && !basicConfig.company_domain && <SelectDatabase selectChange={(value) => handleSelectChange(value)}/>}
      </LoginFormStyles.SelectWrapper>
      {error && <LoginFormStyles.LoginMessage error>{error}</LoginFormStyles.LoginMessage>}
      <LoginFormStyles.LoginForm onSubmit={handleSubmit} onChange={() => handleFormChange()}>
        <InputBar>
          <Input type="text" name="login" value={formInputs.login} onChange={handleInputChange} placeholder=" " />
          <InputLabel/>
          <Label htmlFor="login">{t('login.loginForm')}</Label>
        </InputBar>
        <InputBar>
          <Input type="password" name="password" value={formInputs.password} onChange={handleInputChange} placeholder=" " />
          <InputLabel/>
          <Label htmlFor="password">{t('login.loginFormPassword')}</Label>
        </InputBar>
        <LoginFormStyles.LoginButton type="submit" className={isLogging ? 'loading' : null} disabled={isLogging}>
          <PrimaryButtonText>{isLogging ? t('login.logging') : t('login.loginFormButton')}</PrimaryButtonText>
          <PrimaryButtonIcon>
            {isLogging ? <ButtonLoader/> : <ArrowRight/>}
          </PrimaryButtonIcon>
        </LoginFormStyles.LoginButton>
      </LoginFormStyles.LoginForm>
    </LoginFormStyles.ContentWrapper>
  );
};

export default withNamespaces()(LoginForm);
