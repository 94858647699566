export default {
  login: {
    logoAlt: 'GTV Bus',
    header: 'Logați-vă la panoul dispecerului',
    companyHeader: "Conectați-vă la panoul companiei",
    loginForm: 'Loghin',
    loginFormPassword: 'Parolă',
    forgotPassword: 'Dacă v-ați uitat parola, luați legătură cu administrator',
    loginFormButton: 'Logați-vă',
    logging: 'Logare...',
    verify: 'Verificare...',
    helpText: 'Aveți nevoie de ajutor? Sunați-ne',
    loading: 'Se încarcă...'
  },
  languages: {
    language: 'Limba',
    pl: 'Polski',
    en: 'English',
    ro: 'Română',
    nl: "Nederlands",
    ua: 'Український',
    de: 'Deutsche',
    change: 'Schimbare',
  },
  database: {
    name: 'Bază de date',
    pl: ' Polonia',
    nl: ' Olandeză',
    ro: ' Română'
  },
  route: {
    editRoute: 'Editați ruta',
    cancelEdit: 'Anulați editarea',
    saveChanges: 'Salvează modificările',
    savingChanges: 'Salvând...',
    editAlert1: 'Selectați întinderea traseului pe care doriți să îl editați ',
    editAlert2: 'selectând două puncte ',
    editAlert3: 'pe traseu, apoi ',
    editAlert4: 'setați punctele de ocolire ',
    editAlert5: 'pentru acea secțiune a traseului.',
    taskCancelled: 'Anulat',
    removePoint: 'Ștergeți punctul',
    additionalPoint: 'Punct de ocolire',
  },
  confirmModal: {
    confirmButton: 'Da, plec',
    cancelButton: 'Nu, vreau să termin editarea',
    mainText: 'Modificările aduse traseului nu au fost salvate și vor fi pierdute.',
    boldText: 'Esti sigur ca vrei sa pleci?',
  },
  filters: {
    all: 'Toate',
    departure: 'Plecare',
    back: 'Întoarcere',
    labels: {
      departureDate: 'Data plecarii',
      date: 'Dată',
      tripType: 'Tip de traseu',
      team: 'Codul sucursalei',
    },
    allPassengers: 'Toata lumea',
    presentPassengers: 'Prezent',
    refreshData: "Actualizează datele"
  },
  tabs: {
    info: 'Informații',
    task: 'Sarcină',
  },
  sidebar: {
    vehicles: 'Vehicule',
    drivers: 'Șoferi',
    passengers: 'Pasageri',
    workers: 'Muncitorii',
    events: 'Întâmplări',
    chat: 'Chat'
  },
  cars: {
    searchCar: 'Cgăsiți vehiculul',
    noCarsFound: 'Nu au fost găsite vehicule pentru criterii date!',
    carTypePlaceholder: 'Tip',
    carTypes: {
      all: 'Toate',
      target: 'Finale',
      delivers: 'Care duce în mai multe direcții',
      shippers: 'Care duce la destinație',
    },
    drivers: 'Șoferi',
    trip: 'Traseu',
    tripTypes: {
      target: 'Finală',
      delivers: 'Care duce în mai multe direcții',
      shippers: 'Care duce la destinație',
    },
    vehicleTrip: 'Traseul vehiculului',
    tripNotPlanned: 'Traseul nu a fost încă planificat',
    write: 'Scrieți-ne',
    backToCarsList: 'Întoarcere la lista de vehicule',
    noGpsSignal: 'Fără semnal GPS',
    routeLines: 'Linii de traseu',
  },
  events: {
    eventsHeading: 'Lista întâmplărilor',
    noEventsMessage: 'Nu a fost notat nici un fel de întâmplări!',
    call: 'Sunați-ne',
    message: 'Scrieți-ne'
  },
  drivers: {},
  passenger: {
    searchWorker: 'Căutați un angajat',
    car: 'Vehicul',
    fromTo: 'Destinaţie',
    notFound: 'Nu s-au găsit angajați pentru criteriile date!',
    passengerMissing: 'Absent',
    passengerCancelled: 'Demisie',
    start: 'Start',
    vehicleList: 'Vehicule',
    sharePassengersLocations: 'Partajați locația pasagerilor',
    sharePassengerLocation: 'Partajați locația pasagerului',
    copyLinkToClipboard: 'Copiați linkul în clipboard',
    copyLinksToClipboard: 'Copiați linkurile în clipboard',
    sendInMail: 'Trimite linkul prin e-mail',
    clickToFollowLocation: 'Faceți clic pe link pentru a putea urmări poziția pasagerului',
  },
  companyCars: {
    companyCarsHeading: 'Lista vehiculelor',
    worker: 'angajat',
    workers: 'angajați',
    notFound: 'Nu s-au găsit vehicule pentru criteriile date!',
  },
  api: {
    unexpectedError: 'A aparut o eroare neasteptata!',
  },
};
