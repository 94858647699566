import React, {useEffect, useRef, useState} from 'react'
import {
  DropDownContainer,
  DropDownHeader,
  DropDownListContainer,
  DropDownList,
  ListItem,
  ArrowContainer,
  BoldText
} from './styles'
import {ReactComponent as ArrowDown} from 'assets/icons/arrowDown.svg'
import {withNamespaces} from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {getDatabasesList} from "../../../store/user/thunks";

const SelectDatabase = ({isSearchable = false, onChange, name, t, selectChange}) => {

  const database = useSelector(({user}) => user.database);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(1);
  const myRef = useRef();
  const d = useDispatch();

  useEffect(() => {
    d(getDatabasesList());
  }, [d]);

  const handleClickOutside = e => {
    if (!myRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const returnProperDatabaseName = (locale) => {
    switch (locale) {
      case 1:
        return t('database.pl');
      case 2:
        return t('database.ro');
      case 3:
        return t('database.nl');
      default:
        return t('database.pl');
    }
  };

  const toggleSelect = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => {
    setSelectedOption(value);
    selectChange(value);
    setIsOpen(false);
  };

  return (
    <DropDownContainer ref={myRef} onClick={toggleSelect}>
      <DropDownHeader>
        {t('database.name')}
        <BoldText>{returnProperDatabaseName(selectedOption)}</BoldText>
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList>
            {database && database.map((item, index) => (
              <ListItem
                key={index}
                onClick={() => {
                  onOptionClicked(item.id);
                }}>
                {t('database.name')}
                {returnProperDatabaseName(item.id)}
              </ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
      <ArrowContainer><ArrowDown/></ArrowContainer>
    </DropDownContainer>
  )
};

export default withNamespaces()(SelectDatabase);