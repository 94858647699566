export default {
  login: {
    logoAlt: 'GTV Bus',
    header: 'Увійдіть в панель диспетчера',
    companyHeader: "Увійдіть в панель компанії",
    loginForm: 'Логін',
    loginFormPassword: 'Пароль',
    forgotPassword: 'Якщо ви забули пароль, зверніться до адміністратора',
    loginFormButton: 'Увійти',
    logging: 'Вхі...',
    verify: 'Перевірка...',
    helpText: 'Потрібна допомога? Зателефонуйте нам',
    loading: 'Завантаження...'
  },
  languages: {
    language: 'Мова',
    pl: 'Polski',
    en: 'English',
    ro: 'Română',
    nl: "Nederlands",
    ua: 'Український',
    de: 'Deutsche',
    change: 'Змінити',
  },
  database: {
    name: 'База даних',
    pl: ' Польська',
    nl: ' Нідерландська',
    ro: ' Румунська'
  },
  route: {
    editRoute: 'Змінити маршрут',
    cancelEdit: 'Скасувати зміни',
    saveChanges: 'Записати зміни',
    savingChanges: 'Запис...',
    editAlert1: 'Вибрати відрізок маршруту, який хочете змінити ',
    editAlert2: 'вибираючи два пункти ',
    editAlert3: 'на маршруті, а потім ',
    editAlert4: 'вкажіть пункт/и обїзду ',
    editAlert5: 'для цього відрізка маршруту.',
    taskCancelled: 'Анульовано',
    removePoint: 'Видалити точку',
    additionalPoint: 'Точка обходу',
  },
  confirmModal: {
    confirmButton: 'Так, виходжу',
    cancelButton: 'Ні, хочу закінчити редагування',
    mainText: 'Зміни в маршруті не збережені і будуть втрачені.',
    boldText: 'Ви впевнені, що хочете вийти?',
  },
  filters: {
    all: 'Усе',
    departure: "Від'їзд",
    back: 'Повернення',
    labels: {
      departureDate: "Дата від'їзду",
      date: 'Дата ',
      tripType: 'Тип маршруту',
      team: 'ID філії'
    },
    allPassengers: 'Всім',
    presentPassengers: 'Присутні',
    refreshData: 'Оновити дані',
  },
  tabs: {
    info: 'Інформація',
    task: 'Завдання',
  },
  sidebar: {
    vehicles: 'Транспортні засоби',
    drivers: 'Водії',
    passengers: 'Пасажири',
    workers: 'Персонал',
    events: 'Події',
    chat: 'Чат',
  },
  cars: {
    searchCar: 'Знайти транспортний засіб',
    noCarsFound: 'Транспортні засоби не знайдені за вказаними критеріями!',
    carTypePlaceholder: 'Тип',
    carTypes: {
      all: 'Всі',
      target: 'Цільові',
      delivers: 'Що розвозять',
      shippers: 'Що підвозять',
    },
    drivers: 'Водії',
    trip: 'Маршрут',
    tripTypes: {
      target: 'Цільовий',
      delivers: 'Що розвозить',
      shippers: 'Що підвозить',
    },
    vehicleTrip: 'Маршрут транспортного засобу',
    tripNotPlanned: 'Маршрут ще не запланований',
    write: 'Написати',
    backToCarsList: 'Повернення до списку транспортних засобів',
    noGpsSignal: 'Немає сигналу GPS',
    routeLines: 'Лінії маршруту',
  },
  events: {
    eventsHeading: 'Список подій',
    noEventsMessage: 'Ніяких подій не зафіксовано!',
    call: 'Зателефонувати',
    message: 'Написати'
  },
  drivers: {},
  passenger: {
    searchWorker: 'Знайти персонал',
    car: 'Транспортний засіб',
    fromTo: 'Пункт призначення',
    notFound: 'Персонал не знайдений за вказаними критеріями!',
    passengerMissing: 'Відсутній',
    passengerCancelled: 'Відставка',
    start: 'Старт',
    vehicleList: 'Транспортні засоби',
    sharePassengersLocations: 'Поділіться місцем розташування пасажирів',
    sharePassengerLocation: 'Поділіться місцем розташування пасажира',
    copyLinkToClipboard: 'Копіювати посилання в буфер обміну',
    copyLinksToClipboard: 'Копіювати посилання в буфер обміну',
    sendInMail: 'Надішліть посилання електронною поштою',
    clickToFollowLocation: 'Натисніть на посилання, щоб мати можливість відстежувати позицію пасажира',
  },
  companyCars: {
    companyCarsHeading: 'Список транспортних засобів',
    worker: 'співробітник',
    workers: 'співробітників',
    notFound: 'Транспортні засоби не знайдені за вказаними критеріями!',
  },
  api: {
    unexpectedError: 'Сталася несподівана помилка!',
  },
};
