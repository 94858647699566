import React, {useState, useEffect, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import {getCompanyNaviCars} from "store/cars/thunks";
import {setCompanyCarsPositions} from "store/cars/actions";
import {getCompanyTeams} from "store/companies/thunks";
import {getCompaniesDestinations} from "store/routes/thunks";

import {MapWrapper} from "./styles";
import {mapIcons} from "config/map";
import {withNamespaces} from 'react-i18next';
import theme from 'config/theme';

import Sidebar from "components/sidebar/Sidebar";
import Topbar from "components/topbar/Topbar";
import MapPlatform from "../MapPlatform";
import MapContent from "../MapContent";
import MapMarker from "../MapMarker";
import SidebarPanel from "../../sidebar/SidebarPanel";
import Passengers from 'components/passengers/Passengers';
import CompanyFilters from 'components/topbar/CompanyFilters';
import LoggedUser from 'components/topbar/LoggedUser';
import CompanyCars from "../../companyCars/CompanyCars";
import PageLoader from "components/__common/PageLoader";

const CompanyMap = () => {
  const [activeTab, setActiveTab] = useState(null);
  const [shouldBoundToMarker, setShouldBoundToMarker] = useState(true);
  const [activeEmployeeList, setActiveEmployeeList] = useState(null);

  const companyTeamsLoading = useSelector(({companies}) => companies.companyTeamsLoading);
  const {
    selectedCars,
    companyCarsPositions,
    companyCarsPositionsLoading
  } = useSelector(({cars}) => cars);
  const companiesDestinations = useSelector(({routes}) => routes.companiesDestinations);
  const { companyFilters } = useSelector(({filters}) => filters);
  const d = useDispatch();
  const companyCarsPositionsArray = Object.values(companyCarsPositions);

  const getCompanyNaviCarsFunc = (carIds, routeIds) => {
    if (carIds.length) {
      d(getCompanyNaviCars(carIds, routeIds));
    } else {
      d(setCompanyCarsPositions([]));
    }
  };

  useEffect(() => {
    d(getCompaniesDestinations());
  }, [companyFilters, d]);

  useEffect(() => {
    let isMounted = true;
    let routesInterval;

    if (!companyCarsPositionsLoading) {
      const selectedCarId = selectedCars.map((car) => car.car.id);
      let selectedCarPassengersRouteId = [];
  
      selectedCars.forEach((car) =>
        car.car.items.forEach((passenger =>
          passenger.routes_ids.forEach(route => {
            selectedCarPassengersRouteId = [...selectedCarPassengersRouteId, route];
          }
        ))
      ));
  
      setShouldBoundToMarker(true);
  
      if (selectedCarId.length) {
        getCompanyNaviCarsFunc(selectedCarId, selectedCarPassengersRouteId);
        routesInterval = setInterval(() => {
          if (shouldBoundToMarker) setShouldBoundToMarker(false);
          if (selectedCarId.length) {
            getCompanyNaviCarsFunc(selectedCarId, selectedCarPassengersRouteId);
          } else {
            if (!isMounted) return;
          }
        }, 60000);
      } else {
        d(setCompanyCarsPositions([]));
      }
    }

    return () => {
      clearInterval(routesInterval);
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCars]);

  useEffect(() => {
    d(getCompanyTeams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const returnProperCarData = id => {
    let data = {
      color: null,
      passengersLength: null,
    };
    for (let i = 0; i < selectedCars.length; i++) {
      if (selectedCars[i].car.id === id) {
        data = {
          color: selectedCars[i].color,
          passengersLength: selectedCars[i].car.items_count,
        };
        break;
      }
    }
    return data;
  };

  const handleActiveClick = (id) => {
    setActiveTab(id);
  };

  const handleHideSidebarPanel = useCallback(() => {
    setActiveTab(null);
  }, []);

  const handleOpenListEmployee = index => {
    setActiveEmployeeList(index);
    setActiveTab(1);
  };

  const handleRefreshDestinations = useCallback((passengersType) => {
    d(getCompaniesDestinations(passengersType))
  }, [d]);

  if (companyTeamsLoading) {
    return <PageLoader/>;
  }

  return (
    <MapWrapper>
      <Sidebar
        activeTabId={activeTab}
        clickId={handleActiveClick}/>
      <Topbar>
        <CompanyFilters/>
        <LoggedUser/>
      </Topbar>
      <>
        <SidebarPanel
          activePanel={activeTab}
          panelId={0}>
          <Passengers
            activePanel={activeTab}
            refreshDestinations={handleRefreshDestinations}
            closeSidebar={handleHideSidebarPanel}/>
        </SidebarPanel>
        <SidebarPanel
          activePanel={activeTab}
          panelId={1}>
          <CompanyCars
            closeSidebar={handleHideSidebarPanel}
            activeEmployeeList={activeEmployeeList} />
        </SidebarPanel>
      </>
      <MapPlatform
        isPanelExpanded={activeTab !== null}
        apikey={"AMyLKc4pe2VvrUN0pBM1lqDbAMtlz095C71uP8Xz2Rg"}>
        <MapContent
          center={{
            lat: 52.152366,
            lng: 19.34208,
          }}
          shouldCluster={false}
          companiesDestinations={companiesDestinations}>
          {companiesDestinations.length
            ? companiesDestinations.map((dest, i) => {
              return (
                <MapMarker
                  key={`destination-flag-${i}`}
                  icon={mapIcons.companyDestinationMarker}
                  geoLat={dest.latitude}
                  geoLon={dest.longitude}
                  color={'#000'}
                  zIndex={2}/>
              )
            })
            : <></>
          }
          {selectedCars.length
            ? (
              selectedCars.map(selectedCar => {
                let data = returnProperCarData(selectedCar.car.id);
                return selectedCar.car.flags.map((flag, i) => {
                  return (
                    <MapMarker
                      key={`company-flag-${selectedCar.car.id}-${i}`}
                      icon={mapIcons.companyDestinationMarker}
                      geoLat={flag.lat}
                      geoLon={flag.lon}
                      color={data.color ? data.color : theme.colors.markerGray} />
                  )
                });
              })
            ): <></>
          }
          {companyCarsPositionsArray.length ?
            (companyCarsPositionsArray.map((car) => {
                const geoLat = car.geo_lat;
                const geoLon = car.geo_lon;
                const geoDegree = car.geo_degree;
                const icon = car.geo_error ? mapIcons.carMarkerStopped : mapIcons.carMarker;
                let data = returnProperCarData(car.car_id);
                return (
                  <MapMarker
                    key={car.car_id}
                    onMarkerTap={() => handleOpenListEmployee(car.car_id)}
                    geoLat={geoLat}
                    geoLon={geoLon}
                    icon={icon}
                    geoDegree={car.geo_error ? 0 : geoDegree}
                    isCarMarker={true}
                    color={car.geo_error ? theme.colors.markerGray : data.color ? data.color : theme.colors.markerGray}
                  />
                );
              })
            ) : <></>}
          {companyCarsPositionsArray.length ? (
            companyCarsPositionsArray.map((car) => {
              const geoLat = car.geo_lat;
              const geoLon = car.geo_lon;
              let data = returnProperCarData(car.car_id);
              const carLabelText = car && car.car_number !== null && data.passengersLength > 0 ? `${car.car_number} (${data.passengersLength})`: '-';
              let icon;
              if (car.navi_type === 'phone') {
                if (car.geo_error) {
                  icon = mapIcons.companyCarLabelMarkerGray;
                } else {
                  icon = mapIcons.carLabelMarkerWithPhone;
                }
              } else {
                if (car.geo_error) {
                  icon = mapIcons.companyCarLabelMarkerGray;
                } else {
                  icon = mapIcons.companyCarLabelMarker;
                }
              }
              return (
                <MapMarker
                  key={car.car_id}
                  onMarkerTap={() => handleOpenListEmployee(car.car_id)}
                  geoLat={geoLat}
                  geoLon={geoLon}
                  icon={icon}
                  color={data.color ? data.color : theme.colors.markerGray}
                  isCarLabelMarker={true}
                  boundToMarker={shouldBoundToMarker}
                  labelText={carLabelText}
                />
              );
            })
          ) : <></>}
        </MapContent>
      </MapPlatform>
    </MapWrapper>
  );
};

export default withNamespaces()(CompanyMap);
