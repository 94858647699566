import React, { Fragment } from "react";
import CarsListStyles from "./styles";
import { ReactComponent as PinMapIcon } from "assets/icons/pinmaps.svg";
import { ReactComponent as ClockIcon } from 'assets/icons/clock2.svg';
import { withNamespaces } from 'react-i18next';

const CarsList = ({
  carsList,
  loadingRouteId,
  pickRoute,
  routes,
  routeLoading,
  carsColors,
  onClick,
  t
}) => {
  
  const checkIfRouteIsSelected = (routeId, carId) => {
    let selected = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].id === routeId) {
        selected = {
          isSelected: true,
          color: carsColors[carId],
        };
        break;
      }
    }
    return selected;
  };

  return (
    <CarsListStyles.CarsLists>
      {(carsList && carsList.dowoz && carsList.dowoz.length) ||
      (carsList.cel && carsList.cel.length) ||
      (carsList.rozwoz && carsList.rozwoz.length) ? (
        <>
          {carsList.dowoz &&
            carsList.dowoz.length &&
            carsList.dowoz.map((dowoz, i) => {
              const selected = checkIfRouteIsSelected(dowoz.route_id, dowoz.car.id);
              return (
                <Fragment key={dowoz.route_id}>
                  {i === 0 && (
                    <CarsListStyles.CarsListsItem noHover>
                      <CarsListStyles.CarsListsPin />
                      <CarsListStyles.CarsListsWrapper>
                        <CarsListStyles.CarsListsCar>
                          {dowoz.type === "dowoz" && t('cars.carTypes.shippers')}
                        </CarsListStyles.CarsListsCar>
                      </CarsListStyles.CarsListsWrapper>
                    </CarsListStyles.CarsListsItem>
                  )}
                  <CarsListStyles.CarsListsItem
                    isHover={!routeLoading}
                    color={
                      selected && selected.isSelected ? selected.color : null
                    }
                  >
                    {dowoz.route_id === loadingRouteId ? (
                      <CarsListStyles.CarSpinner />
                    ) : (
                      <CarsListStyles.CarsListsPin
                        onClick={() =>
                          !routeLoading ? pickRoute(dowoz.route_id, dowoz.car.name, dowoz.car.id) : {}
                        }
                        color={
                          selected && selected.isSelected
                            ? selected.color
                            : null
                        }
                      >
                        {selected && selected.isSelected && <PinMapIcon />}
                      </CarsListStyles.CarsListsPin>
                    )}
                    <CarsListStyles.CarsListsWrapper
                      onClick={() => onClick(dowoz.route_id, dowoz.car.name, dowoz.car.id)}
                    >
                      {dowoz.car.name}
                      <CarsListStyles.CarsListsUsr>
                        {dowoz.driversNames ? dowoz.driversNames : null}
                      </CarsListStyles.CarsListsUsr>
                      <CarsListStyles.CarsListStartTimeWrapper>
                        <ClockIcon />
                        <CarsListStyles.CarsListStartTime>{t('passenger.start')} {dowoz.time_begin_str}</CarsListStyles.CarsListStartTime>
                      </CarsListStyles.CarsListStartTimeWrapper>
                    </CarsListStyles.CarsListsWrapper>
                  </CarsListStyles.CarsListsItem>
                </Fragment>
              );
            })}
          {carsList.cel &&
            carsList.cel.length &&
            carsList.cel.map((cel, i) => {
              const selected = checkIfRouteIsSelected(cel.route_id, cel.car.id);
              return (
                <Fragment key={cel.route_id}>
                  {i === 0 && (
                    <CarsListStyles.CarsListsItem noHover>
                      <CarsListStyles.CarsListsPin />
                      <CarsListStyles.CarsListsWrapper>
                        <CarsListStyles.CarsListsCar>
                          {cel.type === "cel" && t('cars.carTypes.target')}
                        </CarsListStyles.CarsListsCar>
                      </CarsListStyles.CarsListsWrapper>
                    </CarsListStyles.CarsListsItem>
                  )}
                  <CarsListStyles.CarsListsItem
                    isHover={!routeLoading}
                    color={
                      selected && selected.isSelected ? selected.color : null
                    }
                  >
                    {cel.route_id === loadingRouteId ? (
                      <CarsListStyles.CarSpinner />
                    ) : (
                      <CarsListStyles.CarsListsPin
                        onClick={() =>
                          !routeLoading ? pickRoute(cel.route_id, cel.car.name, cel.car.id) : {}
                        }
                        color={
                          selected && selected.isSelected
                            ? selected.color
                            : null
                        }
                      >
                        {selected && selected.isSelected && <PinMapIcon />}
                      </CarsListStyles.CarsListsPin>
                    )}
                    <CarsListStyles.CarsListsWrapper
                      onClick={() => onClick(cel.route_id, cel.car.name, cel.car.id)}
                    >
                      {cel.car.name}
                      <CarsListStyles.CarsListsUsr>
                        {cel.driversNames ? cel.driversNames : null}
                      </CarsListStyles.CarsListsUsr>
                      <CarsListStyles.CarsListStartTimeWrapper>
                        <ClockIcon />
                        <CarsListStyles.CarsListStartTime>{t('passenger.start')} {cel.time_begin_str}</CarsListStyles.CarsListStartTime>
                      </CarsListStyles.CarsListStartTimeWrapper>
                    </CarsListStyles.CarsListsWrapper>
                  </CarsListStyles.CarsListsItem>
                </Fragment>
              );
            })}
          {carsList.rozwoz &&
            carsList.rozwoz.length &&
            carsList.rozwoz.map((rozwoz, i) => {
              const selected = checkIfRouteIsSelected(rozwoz.route_id, rozwoz.car.id);
              return (
                <Fragment key={rozwoz.route_id}>
                  {i === 0 && (
                    <CarsListStyles.CarsListsItem noHover>
                      <CarsListStyles.CarsListsPin />
                      <CarsListStyles.CarsListsWrapper>
                        <CarsListStyles.CarsListsCar>
                          {rozwoz.type === "rozwoz" && t('cars.carTypes.delivers')}
                        </CarsListStyles.CarsListsCar>
                      </CarsListStyles.CarsListsWrapper>
                    </CarsListStyles.CarsListsItem>
                  )}
                  <CarsListStyles.CarsListsItem
                    isHover={!routeLoading}
                    color={
                      selected && selected.isSelected ? selected.color : null
                    }
                  >
                    {rozwoz.route_id === loadingRouteId ? (
                      <CarsListStyles.CarSpinner />
                    ) : (
                      <CarsListStyles.CarsListsPin
                        onClick={() =>
                          !routeLoading ? pickRoute(rozwoz.route_id, rozwoz.car.name, rozwoz.car.id) : {}
                        }
                        color={
                          selected && selected.isSelected
                            ? selected.color
                            : null
                        }
                      >
                        {selected && selected.isSelected && <PinMapIcon />}
                      </CarsListStyles.CarsListsPin>
                    )}
                    <CarsListStyles.CarsListsWrapper
                      onClick={() => onClick(rozwoz.route_id, rozwoz.car.name, rozwoz.car.id)}
                    >
                      {rozwoz.car.name}
                      <CarsListStyles.CarsListsUsr>
                        {rozwoz.driversNames ? rozwoz.driversNames : null}
                      </CarsListStyles.CarsListsUsr>
                      <CarsListStyles.CarsListStartTimeWrapper>
                        <ClockIcon />
                        <CarsListStyles.CarsListStartTime>{t('passenger.start')} {rozwoz.time_begin_str}</CarsListStyles.CarsListStartTime>
                      </CarsListStyles.CarsListStartTimeWrapper>
                    </CarsListStyles.CarsListsWrapper>
                  </CarsListStyles.CarsListsItem>
                </Fragment>
              );
            })}
          <CarsListStyles.CarsListsItem emptyItem />
        </>
      ) : (
        <CarsListStyles.CarsNotFound>
          {t('cars.noCarsFound')}
        </CarsListStyles.CarsNotFound>
      )}
    </CarsListStyles.CarsLists>
  );
};

export default withNamespaces()(CarsList)
